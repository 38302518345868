import * as React from 'react';

import { HeadProps, PageProps } from 'gatsby';

import { SITE_LOCALE } from 'app-constants';

import { StileXInfo } from 'stile-shared/src/components/2020/blocks/StileXInfo';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Get Stile X"
      description="View Stile X content in the app"
      slug={props.location.pathname}
    />
  );
}

function StileXPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <StileXInfo locale={SITE_LOCALE} />
    </PageLayout>
  );
}

export default StileXPage;
