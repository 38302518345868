import * as React from 'react';

import styled from 'styled-components';

import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from '../../../../constants';

export const STILE_X_GOOGLE_PLAY = {
  url: GOOGLE_PLAY_STORE_URL,
  src: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
  alt: 'Get Stile X on Google Play',
};

export const STILE_X_APP_STORE = {
  url: APP_STORE_URL,
  src: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1644278400&h=8106249f28593f4d696d0d7974e567c7',
  alt: 'Download Stile X on the App Store',
};

export type DownloadAppButtonProps = {
  url: string;
  src: string;
  alt: string;
  width?: string;
};

export function DownloadAppButton({
  url,
  src,
  alt,
  width = '150px',
  ...props
}: DownloadAppButtonProps) {
  return (
    <Container width={width} {...props}>
      <A href={url} style={{ height: '100%' }}>
        <Image src={src} alt={alt} />
      </A>
    </Container>
  );
}

const Container = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
`;

const A = styled.a`
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const GooglePlayButton = ({ width = '140px', ...props }) => (
  <DownloadAppButton {...STILE_X_GOOGLE_PLAY} width={width} {...props} />
);

export const AppStoreButton = ({ width = '114px', ...props }) => (
  <DownloadAppButton {...STILE_X_APP_STORE} width={width} {...props} />
);
