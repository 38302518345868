import * as React from 'react';

import { navigate } from 'gatsby';

import { CONTACT } from '../../../../constants';

import { getRegionFromLocale } from '../../../../utils/getRegionFromLocale';
import { isBrowser } from '../../../../utils/isBrowser';

import { theme } from '../../../../styles/2020/theme';

import { AppStoreButton, GooglePlayButton } from '../../common/DownloadAppButton';
import { Link } from '../../common/Link';
import { P, PageHeading } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import stileXQrCodeSvg from '../../../../../assets/images/stile-x/stile_x_qr.svg';
import { TextBlock } from '../TextBlock';

const STILE_X_APP_LINK_SCHEME = 'stile-x://';

type ViewSearchParam = 'browser' | 'mobile' | null;

type StileXInfoProps = {
  locale: string;
};

export function StileXInfo({ locale }: StileXInfoProps) {
  const region = getRegionFromLocale(locale);
  let viewParam: ViewSearchParam = null;

  if (isBrowser()) {
    const params = new URL(window.location.href).searchParams;
    const urlParam = params.get('url');
    viewParam = params.get('view') as ViewSearchParam;

    // open stile x app via deep-link if provided
    if (urlParam?.startsWith(STILE_X_APP_LINK_SCHEME)) {
      navigate(urlParam);
    }
  }

  return (
    <TextBlock textAlign="center" space={theme.space.l}>
      <Column space={theme.space.xs}>
        {viewParam === 'browser' ? (
          <PageHeading>This Stile X content is not available on the browser yet.</PageHeading>
        ) : viewParam === 'mobile' ? (
          <PageHeading>
            Do you have the <br /> Stile X app downloaded?
          </PageHeading>
        ) : (
          <PageHeading>Trying to view Stile X content?</PageHeading>
        )}
        <P>
          You need the Stile X app to scan QR codes in your book. The app is available for iOS and
          Android.
        </P>
      </Column>

      {viewParam !== 'mobile' && (
        <Row>
          <img width="200px" src={stileXQrCodeSvg} alt="" />
        </Row>
      )}

      <Row>
        <GooglePlayButton width="200px" />
        <AppStoreButton width="164px" />
      </Row>

      <Column as={P} space={theme.space.s}>
        <span>If you need help getting started, please watch the video at</span>
        <Link url="https://stileapp.com/go/stilexlogin">stileapp.com/go/stilexlogin</Link>
        <span>or reach out to the Stile support team</span>

        <Column space={theme.space.xxs}>
          {region === 'au' && (
            <>
              <span>
                Australia: <Link url={CONTACT.au.phone.url}>{CONTACT.au.phone.text}</Link>
              </span>
              <span>
                New Zealand: <Link url={CONTACT.nz.phone.url}>{CONTACT.nz.phone.text}</Link>
              </span>
            </>
          )}

          {region === 'us' && (
            <span>
              United States: <Link url={CONTACT.us.phone.url}>{CONTACT.us.phone.text}</Link>
            </span>
          )}

          <span>
            International:{' '}
            <Link url={CONTACT.international.phone.url}>{CONTACT.international.phone.text}</Link>
          </span>

          <span>
            Email: <Link url={CONTACT.email.help.url}>{CONTACT.email.help.text}</Link>
          </span>
        </Column>
      </Column>
    </TextBlock>
  );
}
